<template>
  <div class="container demo-div">
    <div class="details-section">
      <div class="font-title">{{ $t("landing.reportDemo.title") }}</div>
      <div class="font-secondary-title">
        {{ $t("landing.reportDemo.content") }}
      </div>
    </div>
    <div class="report-section">
      <div class="report-box-small"></div>
      <div class="report-box" style="">
        <div
          class="revenue-amount"
          style="flex-direction: row;
          display: flex;"
        >
          <span>৳</span>
          <div class="number-1"></div>
          <div class="number-2"></div>
          <div class="number-3"></div>
          <div class="number-3"></div>
        </div>
        <div class="bar-chart">
          <div class="bar-1" style="--timeline: 0.5s;"></div>
          <div class="bar-2" style="--timeline: 1s;"></div>
          <div class="bar-3" style="--timeline: 1.5s;"></div>
          <div class="bar-4" style="--timeline: 2s;"></div>
          <div class="bar-5" style="--timeline: 2.5s;"></div>
          <div class="bar-6" style="--timeline: 3s;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportDemoLayout",
  data() {
    return {
      observer: null,
    };
  },
  components: {},
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const numOne = document.querySelector(".number-1");
          const numTwo = document.querySelector(".number-2");
          const numThree = document.querySelector(".number-3");

          const barOne = document.querySelector(".bar-1");
          const barTwo = document.querySelector(".bar-2");
          const barThree = document.querySelector(".bar-3");
          const barFour = document.querySelector(".bar-4");
          const barFive = document.querySelector(".bar-5");
          const barSix = document.querySelector(".bar-6");

          if (entry.isIntersecting) {
            numOne.classList.add("report-number-1");
            numTwo.classList.add("report-number-2");
            numThree.classList.add("report-number-3");

            barOne.classList.add("report-bar-1");
            barTwo.classList.add("report-bar-2");
            barThree.classList.add("report-bar-3");
            barFour.classList.add("report-bar-4");
            barFive.classList.add("report-bar-5");
            barSix.classList.add("report-bar-6");
            return; // if we added the class, exit the function
          }
        });
      },
      { threshold: 0.75 }
    );

    this.observer.observe(document.querySelector(".report-section"));
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.revenue-amount {
  font: 800 25px system-ui;
}

.report-number-1::after {
  // font: 800 18px system-ui;
  content: counter(count);
  animation: counter 5s linear alternate;
  animation-fill-mode: forwards;
  counter-reset: count 0;
}

.report-number-2::after {
  // font: 800 18px system-ui;
  content: counter(count);
  animation: counter 2s linear alternate;
  animation-fill-mode: forwards;
  counter-reset: count 5;
  animation-iteration-count: 2;
}

.report-number-3::after {
  // font: 800 18px system-ui;
  content: counter(count);
  animation: counter 1s linear alternate;
  animation-fill-mode: forwards;
  counter-reset: count 3;
  animation-iteration-count: 5;
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
  }
  100% {
    counter-increment: count 10;
  }
}

.bar-chart {
  flex-direction: row;
  display: flex;
  height: 120px;
  margin-top: 20px;
  align-items: flex-end;
}

.report-bar-1 {
  height: 0px;
  width: 26px;
  border: solid 2px #f4f7f9;
  background-color: #cdd8e0;
  margin-left: 5px;
  margin-right: 5px;
  animation-name: bar-anim-1;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: var(--timeline);
}

@keyframes bar-anim-1 {
  100% {
    height: 60px;
    transition: max-height 1s;
  }
}

.report-bar-2 {
  height: 0px;
  width: 26px;
  border: solid 2px #f4f7f9;
  background-color: #cdd8e0;
  margin-left: 5px;
  margin-right: 5px;
  animation-name: bar-anim-2;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: var(--timeline);
}

@keyframes bar-anim-2 {
  100% {
    height: 50px;
    transition: max-height 1s;
  }
}

.report-bar-3 {
  height: 0px;
  width: 26px;
  border: solid 2px #f4f7f9;
  background-color: #cdd8e0;
  margin-left: 5px;
  margin-right: 5px;
  animation-name: bar-anim-3;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: var(--timeline);
}

@keyframes bar-anim-3 {
  100% {
    height: 65px;
    transition: max-height 1s;
  }
}

.report-bar-4 {
  height: 0px;
  width: 26px;
  border: solid 2px #f4f7f9;
  background-color: #cdd8e0;
  margin-left: 5px;
  margin-right: 5px;
  animation-name: bar-anim-4;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: var(--timeline);
}

@keyframes bar-anim-4 {
  100% {
    height: 70px;
    transition: max-height 1s;
  }
}
.report-bar-5 {
  height: 0px;
  width: 26px;
  border: solid 2px #f4f7f9;
  background-color: #cdd8e0;
  margin-left: 5px;
  margin-right: 5px;
  animation-name: bar-anim-5;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: var(--timeline);
}

@keyframes bar-anim-5 {
  100% {
    height: 80px;
    transition: max-height 1s;
  }
}

.report-bar-6 {
  height: 0px;
  width: 26px;
  border: solid 2px #f4f7f9;
  background-color: #cdd8e0;
  margin-left: 5px;
  margin-right: 5px;
  animation-name: bar-anim-6;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: var(--timeline);
}

@keyframes bar-anim-6 {
  100% {
    height: 90px;
    transition: max-height 1s;
  }
}

.font-style {
  font-size: 22px;
  margin-top: 10px;
  word-break: break-word;
  color: #95989a;
}

.font-title {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 500;
  text-align: left;
  color: #e2136e;
}

.font-secondary-title {
  font-family: Roboto;
  font-size: 27px;
  font-weight: normal;
  text-align: left;
  color: #000;
  line-height: 1.5;
  max-width: 630px;
}

.demo-div {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  justify-content: space-between;
  align-items: center;
}

.report-section {
  display: flex;
  height: 538px;
  width: 400px;
  justify-content: center;
}

.report-box {
  width: 283px;
  height: 546px;
  background-image: url("../../../assets/image/landing/report-1.svg");
  background-size: contain;
  display: flex;
  padding-top: 90px;
  padding-left: 22px;
  padding-right: 25px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 100px;
  position: absolute;
}

.report-box-small {
  width: 209px;
  height: 412px;
  background-image: url("../../../assets/image/landing/report-2.webp");
  background-size: contain;
  display: flex;
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 25px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 85px;
  margin-bottom: 15px;
  margin-left: -170px;
  position: absolute;
}

@media screen and (max-width: 1024px) {
  .font-style {
    font-size: 18px;
  }

  .report-box-small {
    margin-left: -95px;
  }
  .report-box {
    margin-left: 60px;
  }
}

@media screen and (max-width: 760px) {
  .demo-div {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    margin-top: 30px;
  }

  .details-section {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .font-style {
    font-size: 18px;
    text-align: center;
  }

  .font-title {
    font-size: 23px;
  }

  .font-secondary-title {
    font-size: 16px;
  }

  .report-section {
    width: 0;
    height: 450px;
  }

  .report-box-small {
    margin-left: -100px;
    width: 203px;
    height: 330px;
    margin-top: 45px;
  }

  .report-box {
    margin-left: 40px;
    width: 203px;
    height: 390px;
    padding-top: 50px;
    padding-left: 10px;
  }

  .bar-chart {
    flex-direction: row;
    display: flex;
    height: 20px;
    margin-top: 55px;
    align-items: flex-end;
  }

  .report-bar-1 {
    width: 26px;
  }

  .report-bar-2 {
    width: 26px;
  }

  .report-bar-3 {
    width: 26px;
  }

  .report-bar-4 {
    width: 26px;
  }

  .report-bar-5 {
    width: 26px;
  }

  .report-bar-6 {
    width: 26px;
  }

  @keyframes bar-anim-1 {
    100% {
      height: 30px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-2 {
    100% {
      height: 20px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-3 {
    100% {
      height: 35px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-4 {
    100% {
      height: 45px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-5 {
    100% {
      height: 55px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-6 {
    100% {
      height: 65px;
      transition: max-height 1s;
    }
  }

  .revenue-amount {
    font: 800 18px system-ui;
  }
}

@media screen and (max-width: 450px) {
  .font-style {
    font-size: 16px;
  }

  .font-title {
    font-size: 23px;
  }

  .font-secondary-title {
    font-size: 16px;
  }

  .report-section {
    width: 0;
    height: 450px;
  }

  .report-box-small {
    margin-left: -100px;
    width: 203px;
    height: 330px;
    margin-top: 45px;
  }

  .report-box {
    margin-left: 40px;
    width: 203px;
    height: 390px;
    padding-top: 50px;
    padding-left: 10px;
  }

  .revenue-amount {
    font: 800 18px system-ui;
  }

  .report-bar-1 {
    width: 18px;
  }

  .report-bar-2 {
    width: 18px;
  }

  .report-bar-3 {
    width: 18px;
  }

  .report-bar-4 {
    width: 18px;
  }

  .report-bar-5 {
    width: 18px;
  }

  .report-bar-6 {
    width: 18px;
  }

  @keyframes bar-anim-1 {
    100% {
      height: 30px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-2 {
    100% {
      height: 20px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-3 {
    100% {
      height: 35px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-4 {
    100% {
      height: 45px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-5 {
    100% {
      height: 55px;
      transition: max-height 1s;
    }
  }

  @keyframes bar-anim-6 {
    100% {
      height: 65px;
      transition: max-height 1s;
    }
  }
}
</style>
