<template>
  <div class="register-info-div">
    <v-container>
      <v-row>
        <v-col class="section-1-col">
          <div style="text-align: left;width: 100%;">
            <span class="heading-text">
              {{ $t("landing.registerInfo.businessDashboard") }}
            </span>
          </div>

          <div class="sub-heading-text">
            {{ $t("landing.registerInfo.createStore") }}
          </div>
          <div class="details-text">
            {{ $t("landing.registerInfo.easySetup") }}
          </div>
          <v-card
            class="get-started-button"
            rounded
            outlined
            @click="$router.push({ name: 'sign-up' })"
          >
            <div>
              <span class="get-started-text">
                {{ $t("landing.registerInfo.getStarted") }}
              </span>
            </div>
          </v-card>
          <div class="register-text">
            <span class="register-sub-text">{{ $t("landing.registerInfo.noAccount") }}</span>
            <br/>
            <a
              :href="getPRALink"
              style="color: #e2136e; font-weight: 500;"
              >{{ $t("landing.registerInfo.register") }}
            </a>
          </div>
        </v-col>
        <v-col class="section-2-col" align="center">
          <div style="margin-top: 15px; height: 430px; margin-bottom: 15px;">
            <img
              class="overview-img-div-res"
              style="max-height: 426px;"
              src="@/assets/image/landing/app.webp"
              alt="img"
            />
            <img
              class="overview-img-div-res shoe-style"
              src="@/assets/image/landing/shoe.webp"
              alt="img"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "RegisterInfoLayout",
  components: {},
  data() {
    return {
      myDialogVisible: false,
    };
  },
  mounted() {
    if (this.$route.params.hereToRegister) {
      this.myDialogVisible = true;
    }
  },
  computed: {
    getPRALink() {
      return process.env.VUE_APP_PRA_LINK || "";
    },
  },
};
</script>

<style scoped>
.register-info-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register-sub-layout {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.overview-img-div {
  width: 57%;
}

.section-1-col {
  max-width: 825px;
}

.section-2-col {
  max-width: 355px;
}

.heading-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: normal;
  color: #000;
  margin-bottom: 9px;
}

.sub-heading-text {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 15px;
  max-width: 705px;
}

.details-text {
  font-family: Roboto;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 30px;
  line-height: normal;
  max-width: 628px;
}

.get-started-button {
  width: 425px;
  box-shadow: 3px 5px #000;
  height: 45px;
  border-radius: 25px !important;
  border-color: #000;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #e2136e;
}

.get-started-button:hover {
  background-color: #e2136e;
  color: white;
}

.get-started-text {
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
}

.register-text {
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 26px;
  line-height: normal;
  max-width: 450px;
}

.register-sub-text {
  font-size: 21px;
}

.shoe-style {
  top: -352px;
  position: relative;
  left: -10px;
  animation-name: shoe-anim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  height: 165px;
}

@keyframes shoe-anim {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 950px) {
  .details-text {
    margin-top: 10px;
  }

  .overview-img-div {
    display: none;
  }

  .register-sub-layout {
    align-items: center;
    width: 100%;
  }

  .overview-img-div-res {
    display: block;
    max-height: 300px;
  }
}

@media only screen and (max-width: 450px) {
  .section-2-col {
    max-width: 100%;
  }
  .heading-text {
    font-size: 20px;
  }

  .sub-heading-text {
    font-size: 26px;
  }

  .details-text {
    font-size: 19px;
  }

  .get-started--button {
    width: 320px;
    height: 40px;
  }

  .register-text {
    font-size: 15px;
  }

  .register-sub-text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1024px) {
  .details-text {
    font-size: 19px;
  }

  .register-text {
    font-size: 15px;
  }
}
</style>
