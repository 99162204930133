<template>
  <div class="container base-layout-div">
    <register-info-layout>
    </register-info-layout>
    <feature-layout></feature-layout>
    <chat-demo-layout></chat-demo-layout>
    <store-demo-layout></store-demo-layout>
    <report-demo-layout></report-demo-layout>
    <div class="get-started-div">
      <v-card
          class="get-started-button"
          outlined
          rounded
          @click="$router.push({name: 'sign-up'});"
      >
        <div>
          <span style="font-family: Roboto;">
            {{ $t("landing.registerInfo.getStarted") }}
          </span>
        </div>
      </v-card>
    </div>
    <facts-fig-layout></facts-fig-layout>
    <testimonial-layout></testimonial-layout>
    <facebook-group-layout></facebook-group-layout>
  </div>
</template>

<script>
import RegisterInfoLayout from "./RegisterInfoLayout";
import ChatDemoLayout from "./ChatDemoLayout";
import FeatureLayout from "./FeatureLayout";
import FacebookGroupLayout from "./FacebookGroupLayout";
import StoreDemoLayout from './StoreDemoLayout.vue';
import ReportDemoLayout from './ReportDemoLayout.vue';
import FactsFigLayout from './FactsFigLayout.vue';
import TestimonialLayout from './TestimonialLayout.vue';

export default {
  name: "BaseLayout",
  components: {
    RegisterInfoLayout,
    ChatDemoLayout,
    FeatureLayout,
    FacebookGroupLayout,
    StoreDemoLayout,
    ReportDemoLayout,
    FactsFigLayout,
    TestimonialLayout
  },
}
</script>

<style scoped>
.base-layout-div {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.navigate-down-icon {
  align-self: center;
  width: 92px;
  height: 92px;
  margin-top: 30px;
  margin-bottom: 63px;
}

.advertise-text {
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
  margin-bottom: 63px;
}

.get-started-div {
  width: 100%;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.get-started-button {
  width: 872px;
  height: 69px;
  font-size: 32px;
  font-weight: bold;
  box-shadow: 3px 5px #000;
  border-radius: 50px !important;
  border-color: #000;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #e2136e;
}

.get-started-button:hover {
  background-color: #e2136e;
  color: white;
}

.register-bold-text {
  margin-right: 40px;
  margin-left: 10px;
  font-weight: 500;
  color: #707070;
}

.register-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 152px;
  justify-content: center;
  margin-top: 130px;
}

.register-text {
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

@media only screen and (min-width: 950px) and (max-width: 1264px) {
  .register-text {
    font-size: 25px;
  }
}

@media only screen and (max-width: 949px) {
  .register-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 950px) {
  .register-div {
    flex-direction: column;
    justify-content: center;
  }

  .register-bold-text {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .navigate-down-icon {
    align-self: center;
    width: 53px;
    height: 53px;
    margin-top: 22px;
    margin-bottom: 24px;
  }

  .advertise-text {
    font-size: 18px;
    margin-bottom: 35px;
  }

  .register-text {
    font-size: 15px;
  }

  .get-started-div {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .get-started-button {
    width: 320px;
    height: 40px;
    font-size: 17px;
    font-weight: bold;
    border-radius: 20px !important;
    box-shadow: 3px 5px #000;
  }

  .register-div {
    margin-top: 29px;
    margin-bottom: 10px;
  }
}

</style>
