<template>
  <div class="container demo-div">
    <div class="details-section">
      <div class="font-title">{{ $t("landing.storeDemo.title") }}</div>
      <div class="font-secondary-title">
        {{ $t("landing.storeDemo.content") }}
      </div>
    </div>
    <div style="display: flex;">
      <div class="store-box">
        <img
          class="overview-img-div-res item-1"
          src="@/assets/image/landing/tshirt.webp"
          style="--timeline: 1s;"
          alt="img"
        />
      </div>
      <div class="store-side-items">
        <img
          class="overview-img-div-res item-2"
          src="@/assets/image/landing/headphn.webp"
          style="--timeline: 2s;"
          alt="img"
        />
        <img
          class="overview-img-div-res item-3"
          src="@/assets/image/landing/watch.webp"
          style="--timeline: 3s;"
          alt="img"
        />
        <div class="add-item-box">
          <v-icon color="primary">
            mdi-plus-circle-outline
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreDemoLayout",
  components: {},
  computed: {},
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const itemOne = document.querySelector(".item-1");
          const itemTwo = document.querySelector(".item-2");
          const itemThree = document.querySelector(".item-3");

          if (entry.isIntersecting) {
            itemOne.classList.add("store-item-1");
            itemTwo.classList.add("side-items-animate");
            itemThree.classList.add("side-items-animate");
            return; // if we added the class, exit the function
          }
        });
      },
      { threshold: 0.75 }
    );

    this.observer.observe(document.querySelector(".store-box"));
  },
};
</script>

<style scoped>
.font-style {
  font-size: 22px;
  margin-top: 10px;
  word-break: break-word;
  color: #95989a;
}

.font-title {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 500;
  text-align: left;
  color: #e2136e;
}

.font-secondary-title {
  font-family: Roboto;
  font-size: 27px;
  font-weight: normal;
  text-align: left;
  color: #000;
  line-height: 1.5;
  max-width: 630px;
}

.demo-div {
  display: flex;
  padding-left: 0px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.store-box {
  background-image: url("../../../assets/image/landing/tshirt-box.svg");
  background-size: contain;
  display: flex;
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 25px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  height: 531px;
  width: 269px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.store-side-items {
  max-width: 156px;
}

.item-1 {
  z-index: 999;
  height: 239px;
  margin-bottom: 10px;
  transform: scaleX(0);
}

.store-item-1 {
  animation-name: shirt-anim;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: var(--timeline);
}

.item-2 {
  margin-top: 15px;
  height: 0px;
}

.item-3 {
  margin-top: 15px;
  height: 0px;
}

.side-items-animate {
  animation-name: msg-anim;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-delay: var(--timeline);
}

.add-item-box {
  margin-top: 15px;
  border-style: dashed;
  width: 162px;
  height: 152px;
  justify-content: center;
  align-content: center;
  display: flex;
}

@keyframes shirt-anim {
  10% {
    transform: scale(.1);
  }
  20% {
    transform: scale(.20);
  }
  30% {
    transform: scale(.30);
  }
  40% {
    transform: scale(.40);
  }
  50% {
    transform: scale(.5);
  }
  60% {
    transform: scale(.60);
  }
  70% {
    transform: scale(.70);
  }
  80% {
    transform: scale(.80);
  }
  90% {
    transform: scale(.90);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes msg-anim {
  100% {
    height: 156px;
    transition: max-height 1s;
  }
}

@media screen and (max-width: 1024px) {
  .font-style {
    font-size: 18px;
  }

  .store-side-items {
    max-width: 118px;
  }

  .add-item-box {
    width: 117px;
    height: 105px;
  }

  @keyframes msg-anim {
    100% {
      height: 115px;
      transition: max-height 1s;
    }
  }
}

@media screen and (max-width: 760px) {
  .demo-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 30px;
  }
  .font-style {
    font-size: 18px;
    text-align: center;
  }

  .font-title {
    font-size: 23px;
  }

  .font-secondary-title {
    font-size: 16px;
  }

  .store-box {
    margin-top: 15px;
    height: 390px;
    width: 203px;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .store-side-items {
    max-width: 118px;
  }

  .store-item-1 {
    height: 166px;
  }

  .add-item-box {
    width: 117px;
    height: 105px;
  }

  @keyframes msg-anim {
    100% {
      height: 115px;
      transition: max-height 1s;
    }
  }
}

@media screen and (max-width: 400px) {
  .details-section {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .font-style {
    font-size: 16px;
  }

  .font-title {
    font-size: 23px;
  }

  .font-secondary-title {
    font-size: 16px;
  }

  .store-box {
    margin-top: 15px;
    height: 390px;
    width: 203px;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .store-side-items {
    max-width: 118px;
  }

  .store-item-1 {
    height: 166px;
  }

  .add-item-box {
    width: 117px;
    height: 105px;
  }

  @keyframes msg-anim {
    100% {
      height: 115px;
      transition: max-height 1s;
    }
  }
}
</style>
