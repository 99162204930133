<template>
  <div class="landing-page-container">
    <header-layout></header-layout>

    <div class="promotion-container promotion-text">
      <div v-html="referralPromotionText" :class="getReferralMessageClass(referralMessageState)"></div>
    </div>

    <base-layout></base-layout>
    <custom-page-footer
        :termsAndConditions="promotionTandC"
    ></custom-page-footer>
  </div>
</template>

<script>
import HeaderLayout from "./common/HeaderLayout";
import BaseLayout from "./common/BaseLayout";
import CustomPageFooter from "cpp-js-util/components/common/CustomPageFooter";

export default {
  name: "LandingLayout",
  components: {
    HeaderLayout,
    BaseLayout,
    CustomPageFooter,
  },
  created() {
    this.loadReferralConfig();
  },
  data() {
    return {
      isLoading: false,

      referralMessageState: 'initial', // 'initial', 'loaded', 'displayed'
      landingPageConfig: {},

      initialPromotionText: {
        bn: "<p style='text-align: center;color:#fff;margin-bottom: 0 !important;'> বিকাশ ব্যবসা ড্যাশবোর্ডে আপনাকে স্বাগতম </p>",
        en: "<p style='text-align: center;color:#fff;margin-bottom: 0 !important;'> Welcome to bKash Business Dashboard </p>"
      },
    }
  },
  methods: {
    async loadReferralConfig() {
      const reqObj = {};
      this.isLoading = true;
      try {
        const {data} = await this.$cppClientExternalServices.post("referral/configuration/get", reqObj);
        await this.sleep(1000);
        this.isLoading = false;

        if (!data.referralCampaignConfig) {
          return;
        }

        const parsedData = JSON.parse(data.referralCampaignConfig);
        this.landingPageConfig = parsedData.landingPageReferralTextConfig;
        console.log(this.landingPageConfig);

        this.referralMessageState = 'loaded';
        await this.sleep(1000);
        this.referralMessageState = 'displayed';

      } catch ({response}) {
        this.isLoading = false;
      }
    },

    getReferralMessageClass(referralMessageState) {
      if (referralMessageState === "loaded") {
        return "animate-fade-out"
      } else if (referralMessageState === "displayed") {
        return "animate-fade-in";
      }
    },

    async sleep(duration) {
      return new Promise((accept) => setTimeout(accept, duration))
    }

  },
  computed: {
    promotionTandC() {
      return (
          process.env.VUE_APP_TERMS_AND_CONDITION_TERMS_AND_CONDITION_URL || ""
      );
    },
    lang() {
      return this.$i18n.locale === 'বাংলা' ? 'bn' : 'en';
    },
    referralPromotionText() {
      if (this.referralMessageState === "displayed") {
        return this.landingPageConfig.promotionalText[this.lang];
      } else {
        return this.initialPromotionText[this.lang];
      }
    }
  },
  mounted() {
    console.log("new content loaded");
    this.$gtag.event("landed on landing page", {
      event_category: "Landing Page",
    });
  },
}
</script>

<style lang="scss" scoped>
$tabLS: 900px;
$mobileW: 750px;

.container {
  max-width: 1185px;
}

.landing-page-container {
  background-color: #ffffff;
  height: 100vh;
}

.promotion-container {
  display: flex;
  width: 100%;
  background-color: #282828;
  justify-content: center;
  flex-direction: row;
  padding: 10px 1px;
  align-items: center;
}

.promotion-text {
  font-size: 30px;
  text-align: center;
  @media (max-width: $mobileW) {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.promotion-tc {
  font-weight: 800;
  color: #e2136e;
  margin-left: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0.1;
  }

  0% {
    opacity: 1;
  }
}

.animate-fade-out {
  animation: fadeOut 1s ease-out forwards;
}

.animate-fade-in {
  animation: fadeIn 1s ease-in forwards;
}
</style>
