<template>
  <div class="container demo-div">
    <div class="font-title">{{ $t("landing.testimonial.title") }}</div>
    <div style="display: flex; width: 100%;">
      <v-container>
        <v-row align="center">
          <v-col align="center" :cols="colValue">
            <div style="height: 220px;">
              <div class="logo-style logo-1"></div>
              <v-card outlined class="card-style">
                <v-card-title
                  class="justify-center"
                  style="color: #e2136e;font-family: Roboto;
                  font-size: 18px;
                  font-weight: 500; padding-bottom: 5px;"
                  >Nabila Rahman</v-card-title
                >
                <v-card-text
                  style="font-size: 13px; font-weight: normal font-style: normal;line-height: normal;color: #000;"
                >
                  “bKash business dashboard has eased my pain of taking online
                  payment and tracking them. It is helping me to scale my
                  business and grow my customer base significantly.”
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col align="center" :cols="colValue">
            <div style="height: 220px;">
              <div class="logo-style logo-2"></div>
              <v-card outlined class="card-style">
                <v-card-title
                  class="justify-center"
                  style="color: #e2136e;font-family: Roboto;
                  font-size: 18px;
                  font-weight: 500; padding-bottom: 5px;"
                  >Atikur Rahman</v-card-title
                >
                <v-card-text
                  style="font-size: 13px; font-weight: normal;font-style: normal;line-height: normal;color: #000;"
                >
                  “Excellent platform for me to create customize payment links
                  for different products. It helps me to track my transactions
                  and also providing a smooth money management journey.”
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col align="center" :cols="colValue">
            <div style="height: 220px;">
              <div class="logo-style logo-3"></div>
              <v-card outlined class="card-style">
                <v-card-title
                  class="justify-center"
                  style="font-family: Roboto;font-size: 18px;font-weight: 500; color: #e2136e; padding-bottom: 5px;"
                  >Jannat Hasan</v-card-title
                >
                <v-card-text
                  style="font-size: 13px; font-weight: normal;font-style: normal;line-height: normal;color: #000;"
                >
                  “Super experience using the platform. From creating payment
                  link to keeping product updates, this platform is a lifesaver
                  for me! Please keep providing this service “
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialLayout",
  components: {},
  computed: {
    colValue() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 4;
        case "lg":
          return 4;
        case "xl":
          return 4;
        default:
          return 4;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-style {
  width: 103px;
  height: 103px;
  border: solid 2px #282828;
  border-radius: 50%;
  position: relative;
  right: 120px;
  z-index: 5;
  background-size: contain;
}

.logo-1 {
  background-image: url("../../../assets/image/landing/ninety-nine.webp");
  background-color: white;
}

.logo-2 {
  background-image: url("../../../assets/image/landing/gadstyle.webp");
  background-color: green;
}

.logo-3 {
  background-image: url("../../../assets/image/landing/ayesha-mart.webp");
  background-color: black;
}

.card-style {
  width: 290px;
  height: 156px;
  box-shadow: 7px 9px #e2136e;
  border: solid 2px;
  border-radius: 0;
  margin: 23.5px 0;
  position: relative;
  top: -72px;
  right: -22px;
}

.font-title {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 500;
  text-align: left;
  color: #e2136e;
}

.demo-div {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  justify-content: center;
  align-items: baseline;
}

@media screen and (max-width: 1024px) {
  .font-title {
    font-size: 30px;
  }

  .frame-div {
    width: 453px;
    height: 286px;
  }
}

@media screen and (max-width: 760px) {
  .demo-div {
    flex-direction: column;
    align-items: baseline;
    padding: 0px;
    margin-top: 30px;
  }

  .font-title {
    font-size: 24px;
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .font-title {
    font-size: 21px;
  }
}
</style>
