<template>
  <div>
    <div class="layout-div">
      <div style="text-align: center;">
        <span class="feature-text">
          {{ $t("landing.features.paymentExperience.t1")
          }}<span style="color: #e2136e;">{{
            $t("landing.features.paymentExperience.t2")
          }}</span
          >{{ $t("landing.features.paymentExperience.t3")
          }}<span style="color: #e2136e;">{{
            $t("landing.features.paymentExperience.t4")
          }}</span
          >{{ $t("landing.features.paymentExperience.t5") }}
        </span>
      </div>
      <div class="feature-items">
        <v-card outlined class="card-style">
          <v-card-title>{{
            $t("landing.features.pageManagement.title")
          }}</v-card-title>
          <v-card-text class="card-content">{{
            $t("landing.features.pageManagement.content")
          }}</v-card-text>
        </v-card>
        <v-card outlined class="card-style">
          <v-card-title>{{ $t("landing.features.report.title") }}</v-card-title>
          <v-card-text class="card-content">{{
            $t("landing.features.report.content")
          }}</v-card-text>
        </v-card>
        <v-card outlined class="card-style">
          <v-card-title>{{ $t("landing.features.store.title") }}</v-card-title>
          <v-card-text class="card-content">{{
            $t("landing.features.store.content")
          }}</v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureLayout",
};
</script>

<style scoped>
.layout-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.feature-text {
  font-size: 33px;
  font-weight: 500;
  text-align: center;
  color: #000;
  font-family: Roboto;
}

.feature-items {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.card-style {
  width: 329px;
  box-shadow: 7px 9px;
  border: solid 2px #e2136e;
  border-radius: 0;
  margin: 23.5px 23px;
  height: 135px;
}

.card-content {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  color: #000 !important;
  line-height: normal;
}

@media only screen and (max-width: 950px) {
  .feature-item-div {
    margin-bottom: 15px;
    align-items: center;
  }

  .feature-items {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 450px) {
  .feature-text {
    font-size: 23px;
  }
  .feature-items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-style {
    margin: 23.5px 0;
  }
}
</style>
