<template>
  <div class="group-layout">
    <span class="details-text">
      {{ $t("landing.facebookGroup.t1") }}<a :href="facebookGroupLink" target="_blank">
      <b style="color: #2699fb; margin-left: 5px;">{{ $t("landing.facebookGroup.t2") }}</b>
      </a> {{ $t("landing.facebookGroup.t3") }}
      {{ $t("landing.facebookGroup.t4") }}
    </span>
    <div class="fb-group group-web-layout" :data-href="facebookGroupLink"
         data-width="592"
         data-show-metadata="true">
      <blockquote :cite="facebookGroupLink"
                  class="fb-xfbml-parse-ignore">bKash Online Biz: Pilot User Group
      </blockquote>
    </div>

    <div class="fb-group group-responsive-layout" :data-href="facebookGroupLink"
         data-width="302"
         data-show-metadata="true">
      <blockquote :cite="facebookGroupLink"
                  class="fb-xfbml-parse-ignore">bKash Online Biz: Pilot User Group
      </blockquote>
    </div>

  </div>
</template>

<script>
export default {
  name: "FacebookGroupLayout",
  mounted() {
    this.$loadScript(
      process.env.VUE_APP_FACEBOOK_SCRIPT_URL
    )
      .then(() => {
        this.$nextTick(() => {
          window.FB.XFBML.parse();
        });
      })
      .catch(() => {
        console.log("SCRIPT LAODING ERROR");
      });
  },
  computed: {
    facebookGroupLink() {
      return process.env.VUE_APP_FACEBOOK_GROUP_URL || '';
    },
  }
}
</script>

<style scoped>
.group-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 93px;
  margin-bottom: 76px;
}

.group-web-layout {
  display: block;
}

.group-responsive-layout {
  display: none;
}

.details-text {
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-right: 28px;
  color: #707070;
}

@media only screen and (max-width: 950px) {
  .group-layout {
    flex-direction: column;
  }

  .details-text {
    margin-top: 28px;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .details-text {
    font-size: 20px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .group-web-layout {
    display: none;
  }

  .group-responsive-layout {
    display: block;
  }

  .group-layout {
    margin-bottom: 48px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 350px) {
  .details-text {
    font-size: 13px;
  }
}
</style>
