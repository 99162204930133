<template>
  <div class="container demo-div">
    <div class="font-title">
      {{ $t("landing.factsFig.title") }}
    </div>
    <div style="display: block;">
      <div style="display: flex; width: 100%;">
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-card class="card-style box-shadow-1" outlined style="">
                    <v-card-title class="card-title text-color--pink"
                      >100K +</v-card-title
                    >
                    <v-card-text class="card-content text-color--black"
                      >Sign up on Dashboard</v-card-text
                    >
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="card-style box-shadow-1" outlined>
                    <v-card-title class="card-title text-color--pink"
                      >600K +</v-card-title
                    >
                    <v-card-text class="card-content text-color--black"
                      >Transacting Customers</v-card-text
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-card class="card-style box-shadow-2" outlined>
                    <v-card-title class="card-title text-color--black"
                      >800K +</v-card-title
                    >
                    <v-card-text class="card-content text-color--pink"
                      >Total Transactions</v-card-text
                    >
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="card-style box-shadow-2" outlined>
                    <v-card-title class="card-title text-color--black"
                      >600M +</v-card-title
                    >
                    <v-card-text class="card-content text-color--pink"
                      >Transaction Amount</v-card-text
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FactFigLayout",
  components: {},
  computed: {
    colValue() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 3;
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.font-title {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 500;
  text-align: left;
  color: #e2136e;
}

.report-number-1::after {
  font: 800 18px system-ui;
  content: counter(count);
  animation: counter 5s linear alternate;
  animation-fill-mode: forwards;
  counter-reset: count 0;
}

.report-number-2::after {
  font: 800 18px system-ui;
  content: counter(count);
  animation: counter 2s linear alternate;
  animation-fill-mode: forwards;
  counter-reset: count 5;
  animation-iteration-count: 2;
}

.report-number-3::after {
  font: 800 18px system-ui;
  content: counter(count);
  animation: counter 1s linear alternate;
  animation-fill-mode: forwards;
  counter-reset: count 3;
  animation-iteration-count: 5;
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
  }
  100% {
    counter-increment: count 10;
  }
}

.card-style {
  width: 169px;
  height: 164px;
  border: solid 2px;
  border-radius: 0;
  margin: 23.5px 0;

  .card-title {
    font-family: Roboto;
    font-size: 38px;
    font-weight: 500;
  }

  .text-color--pink {
    color: #e2136e;
  }

  .text-color--black {
    color: #282828;
  }

  .card-content {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-top: 5px;
  }
}

.box-shadow-1 {
  box-shadow: 7px 9px;
}

.box-shadow-2 {
  box-shadow: 7px 9px #e2136e;
}

.demo-div {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  justify-content: space-between;
  // align-items: center;
}

@media screen and (max-width: 1024px) {
  .font-style {
    font-size: 18px;
  }

  .frame-div {
    width: 453px;
    height: 286px;
  }
}

@media screen and (max-width: 760px) {
  .demo-div {
    flex-direction: column;
    align-items: baseline;
    padding: 0px;
    margin-top: 30px;
  }
  .card-style {
    width: 130px;
    height: 130px;
    box-shadow: 7px 9px;

    .card-title {
      font-size: 27px;
    }

    .card-content {
      font-size: 15px;
      padding-top: 0;
    }
  }

  .font-title {
    font-size: 23px;
  }
}

@media screen and (max-width: 400px) {
  .card-style {
    width: 130px;
    height: 130px;
    box-shadow: 7px 9px;

    .card-title {
      font-size: 27px;
    }
  }

  .font-title {
    font-size: 23px;
  }
}
</style>
