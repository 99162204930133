<template>
  <div class="container demo-div">
    <div class="details-section">
      <div class="font-title">{{ $t("landing.chatDemo.title") }}</div>
      <div class="font-secondary-title">
        {{ $t("landing.chatDemo.content") }}
      </div>
    </div>
    <div style="margin-bottom: 40px;">
      <div class="chat-box">
        <img
            alt="img"
            class="overview-img-div-res msg-1"
            src="@/assets/image/landing/chat-msg-1.png"
            style="--timeline: 1s;"
        />
        <img
            alt="img"
            class="overview-img-div-res msg-2"
            src="@/assets/image/landing/chat-msg-2.png"
            style="--timeline: 2s;"
        />
        <img
            alt="img"
            class="overview-img-div-res msg-3"
            src="@/assets/image/landing/chat-msg-3.png"
            style="--timeline: 3s;"
        />
        <img
            alt="img"
            class="overview-img-div-res msg-4"
            src="@/assets/image/landing/chat-msg-4.png"
            style="--timeline: 5s;"
        />
        <img
            alt="img"
            class="overview-img-div-res msg-5"
            src="@/assets/image/landing/chat-msg-5.png"
            style="--timeline: 6s;"
        />
        <img
            alt="img"
            class="overview-img-div-res msg-6"
            src="@/assets/image/landing/chat-msg-6.png"
            style="--timeline: 7s;"
        />
        <img
            alt="img"
            class="overview-img-div-res msg-7"
            src="@/assets/image/landing/chat-msg-7.png"
            style="--timeline: 8s;"
        />
      </div>
      <div style="display: flex;">
        <img
            alt="img"
            class="fb-logo"
            src="@/assets/image/landing/facebook.svg"
        />
        <div class="social-logo">
          <img
              alt="img"
              class="logo-icon"
              src="@/assets/image/landing/whatsapp.svg"
          />
        </div>
        <div class="social-logo">
          <img
              alt="img"
              class="logo-icon"
              src="@/assets/image/landing/instagram.svg"
          />
        </div>
        <div class="social-logo">
          <img
              alt="img"
              class="logo-icon"
              src="@/assets/image/landing/tiktok.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatDemoLayout",
  components: {},
  computed: {
    getPRALink() {
      return process.env.VUE_APP_PRA_LINK || "";
    },
    videoURL() {
      return process.env.VUE_APP_BKASH_VIDEO_URL;
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const msgOne = document.querySelector(".msg-1");
            const msgTwo = document.querySelector(".msg-2");
            const msgThree = document.querySelector(".msg-3");
            const msgFour = document.querySelector(".msg-4");
            const msgFive = document.querySelector(".msg-5");
            const msgSix = document.querySelector(".msg-6");
            const msgSeven = document.querySelector(".msg-7");

            if (entry.isIntersecting) {
              console.log("yesssssss");
              msgOne.classList.add("app-msg-animate");
              msgTwo.classList.add("app-msg-animate");
              msgThree.classList.add("app-msg-animate");
              msgFour.classList.add("app-msg-animate");
              msgFive.classList.add("app-msg-animate");
              msgSix.classList.add("app-msg-animate");
              msgSeven.classList.add("app-msg-animate");
              return; // if we added the class, exit the function
            }
          });
        },
        {threshold: 0.75}
    );

    this.observer.observe(document.querySelector(".chat-box"));
  },
};
</script>

<style lang="scss" scoped>
.font-style {
  font-size: 22px;
  margin-top: 10px;
  word-break: break-word;
}

.font-title {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 500;
  text-align: left;
  color: #e2136e;
}

.font-secondary-title {
  font-family: Roboto;
  font-size: 27px;
  font-weight: normal;
  text-align: left;
  color: #000;
  line-height: 1.5;
  max-width: 630px;
}

.details-section {
  margin-top: -33px;
}

.demo-div {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  justify-content: space-between;
  align-items: center;
}

.chat-box {
  width: 330px;
  background-image: url("../../../assets/image/landing/chatbox.png");
  background-size: contain;
  display: flex;
  padding-top: 73px;
  padding-left: 15px;
  padding-right: 44px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  height: 539px;
  margin-bottom: 15px;
}

.social-logo {
  background-color: black;
  padding: 5px;
  border-radius: 50%;
  width: 61px;
  height: 61px;
  margin-right: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.logo-icon {
  height: 35px;
}

.fb-logo {
  height: 61px;
  width: 61px;
  margin-right: 16px;
}

.msg-1 {
  z-index: 999;
  height: 23px;
  margin-bottom: 10px;
  opacity: 0;
}

.msg-2 {
  z-index: 999;
  height: 23px;
  margin-bottom: 10px;
  opacity: 0;
}

.msg-3 {
  z-index: 999;
  height: 23px;
  margin-bottom: 10px;
  opacity: 0;
}

.msg-4 {
  z-index: 999;
  height: 23px;
  margin-bottom: 10px;
  opacity: 0;
  margin-left: auto;
}

.msg-5 {
  z-index: 999;
  height: 23px;
  margin-bottom: 10px;
  opacity: 0;
  margin-left: auto;
}

.msg-6 {
  z-index: 999;
  height: 23px;
  margin-bottom: 10px;
  opacity: 0;
  margin-left: auto;
}

.msg-7 {
  z-index: 999;
  height: 134px;
  margin-bottom: 10px;
  opacity: 0;
  margin-left: auto;
}

.app-msg-animate {
  animation-name: msg-anim;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-delay: var(--timeline);
}

@keyframes msg-anim {
  0% {
    opacity: 0;
  }
  /* 25%  {width: 50px;} */
  50% {
    opacity: 0.5;
  }
  /* 75% {width: 150px;} */
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  .font-style {
    font-size: 18px;
  }

  .frame-div {
    width: 453px;
    height: 286px;
  }
}

@media screen and (max-width: 760px) {
  .demo-div {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    margin-top: 30px;
  }

  .details-div {
    width: 100%;
    padding: 12px 18px 40px 20px;
    align-items: center;
    margin-top: 21px;
    margin-left: 0px;
  }

  iframe {
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
  }

  .font-style {
    font-size: 18px;
    text-align: center;
  }

  .details-section {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .font-title {
    font-size: 23px;
  }

  .font-secondary-title {
    font-size: 16px;
  }

  .chat-box {
    width: 230px;
    height: 390px;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 25px;
  }

  .social-logo {
    width: 41px;
    height: 41px;
    margin-right: 16px;
  }

  .fb-logo {
    height: 41px;
    width: 41px;
  }

  .logo-icon {
    height: 22px;
  }

  .msg-1 {
    height: 16px;
  }

  .msg-2 {
    height: 16px;
  }

  .msg-3 {
    height: 16px;
  }

  .msg-4 {
    height: 16px;
  }

  .msg-5 {
    height: 16px;
  }

  .msg-6 {
    height: 16px;
  }

  .msg-7 {
    height: 89px;
  }
}

@media screen and (max-width: 400px) {
  .font-style {
    font-size: 16px;
  }

  .details-section {
    margin-top: 0px;
  }

  .font-title {
    font-size: 23px;
  }

  .font-secondary-title {
    font-size: 16px;
  }

  .chat-box {
    width: 230px;
    height: 390px;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 25px;
  }

  .social-logo {
    width: 41px;
    height: 41px;
    margin-right: 16px;
  }

  .fb-logo {
    height: 41px;
    width: 41px;
  }

  .logo-icon {
    height: 22px;
  }

  .msg-1 {
    height: 16px;
  }

  .msg-2 {
    height: 16px;
  }

  .msg-3 {
    height: 16px;
  }

  .msg-4 {
    height: 16px;
  }

  .msg-5 {
    height: 16px;
  }

  .msg-6 {
    height: 16px;
  }

  .msg-7 {
    height: 89px;
  }
}
</style>
