<template>
  <v-card class="header-container">
    <div class="container item-container">
      <img class="landing-bkash-logo" src="@/assets/image/bKash-logo.svg" alt="img">
      <div class="drop-down-div">
        <v-menu
            transition="slide-y-transition"
            bottom>
          <template v-slot:activator="{ on, attrs }">
            <div style="display: flex; flex-direction: row;" v-bind="attrs" v-on="on">
              <span class="language-text">
                {{ lang }}
              </span>
              <img class="drop-down-arrow" src="@/assets/image/landing/arrow-ios-downward.svg" alt="img">
            </div>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, i) in languages"
                :key="i">
              <v-list-item-title class="drop-down-item" @click="changeLang(item)">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <a
            href="/sign-in"
            v-if="!isLogggedIn"
            class="landing-sign-in"
            rounded
            style="margin-right: 5px;"
        >{{ $t("landing.signIn") }}
        </a>
        <v-btn
            href="/dashboard"
            v-if="isLogggedIn"
            color="primary"
            class="landing-sign-in dashboard-button"
            rounded
        >{{ $t("landing.dashboard") }}
        </v-btn>
        <v-btn
            href="/sign-up"
            v-if="!isLogggedIn"
            color="primary"
            class="landing-sign-up"
            rounded
        >{{ $t("landing.signup") }}
        </v-btn>
        <!-- <v-btn
            href="/referandearn"
            color="primary"
            class="landing-sign-in refer-btn"
            outlined
        >{{ $t("landing.refer") }}
        </v-btn> -->
        <!-- Blog is temporarily removed -->
        <!-- <v-btn
            href="/blog-list"
            color="primary"
            class="landing-sign-in"
            outlined
        >{{ $t("landing.blogHome") }}
        </v-btn> -->
      </div>
    </div>

  </v-card>
</template>

<script>
export default {
  name: "HeaderLayout",
  data() {
    return {
      languages: ['eng', 'বাংলা'],
      lang: this.$i18n.locale || 'বাংলা'
    }
  },
  computed: {
    isLogggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    changeLang(language) {
      console.log(language)
      this.lang = language;
      this.$i18n.locale = language
      localStorage.setItem('lang', language);
    }
  },
}
</script>

<style scoped>
.container {
  max-width: 1185px;
}

.header-container {
  background-color: #FFFFFF;
  width: 100%;
  height: 105px;
  box-shadow: none !important;
}

.item-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
}

.drop-down-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drop-down-arrow {
  height: 27px;
  width: 29px;
  cursor: pointer;
}

.drop-down-item {
  cursor: pointer;
  text-transform: capitalize !important;
}

.landing-sign-in {
  width: 110px;
  max-height: 49px !important;
  margin-left: 8px;
  font-size: 21px;
  color: black;
  justify-content: center;
  align-content: center;
  display: flex;
}

.landing-sign-in:hover {
  text-decoration: underline;
}

.dashboard-button {
  width: 155px !important;
}

.landing-sign-up {
  width: 169px;
  height: 49px !important;
  margin-left: 8px;
  font-size: 21px;
}

.landing-bkash-logo {
  width: 156.7px;
  height: 71.9px;
}

.language-text {
  text-transform: capitalize !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: 5px;
}

@media only screen and (max-width: 1024px) {
  .landing-sign-in {
    width: 100px;
    margin-left: 8px;
    height: 30px !important;
    font-size: 15px;
  }

  .landing-sign-up {
    width: 100px;
    margin-left: 8px;
    height: 30px !important;
    font-size: 15px;
  }

  .landing-bkash-logo {
    width: 108px;
    height: 49px;
    margin-left: 30px;
  }

  .header-container {
    height: 71px;
  }
}

.refer-btn {
  animation: wiggle 2s linear infinite;
  animation-delay: 10s;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@media only screen and (max-width: 450px) {
  .item-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .landing-sign-in {
    width: auto !important;
    height: 25px !important;
    margin-left: 5px;
    font-size: 15px;
    padding: 0px !important;
  }

  .dashboard-button {
    width: 112px !important;
  }

  .landing-sign-up {
    width: auto !important;
    height: 30px !important;
    margin-left: 5px;
    font-size: 15px;
    padding: 2 0px !important;
  }

  .landing-bkash-logo {
    width: 90px;
    height: 41px;
    margin-left: 0px;
  }

  .language-text {
    font-size: 14px;
  }

  .drop-down-arrow {
    width: 20px;
  }
}
</style>
